import EditIcon from "@mui/icons-material/Edit";
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import { withSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import EditPostForm from "./edit-post";
import PostCard from "./post-card";
import "./Post.scss";

const Posts = ({ enqueueSnackbar }) => {
  const [posts, setPosts] = useState([]);
  const username = localStorage.getItem("username");
  const getPosts = async () => {
    const resp = await fetch("https://backend.mihirmanek.workers.dev/posts");
    const postsResp = await resp.json();
    setPosts(postsResp);
  };

  const addPost = async (post) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(post),
    };
    const resp = await fetch(
      "https://backend.mihirmanek.workers.dev/posts",
      requestOptions
    );
    if (resp.status === 200) {
      const postData = await resp.json();
      setPosts([postData, ...posts]);
      handleClose();
    } else {
      enqueueSnackbar("An error occurred while creating the post", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    getPosts();
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleFormSubmit = (data) => {
    // console.log(data);
    addPost({ ...data, username });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePostChange = () => {
    getPosts();
  };

  return (
    <div>
      {posts
        .sort((a, b) => (b.likes?.length || 0) - (a.likes?.length || 0))
        .map((post) => (
          <div key={post.id} className="post">
            <PostCard
              key={post.id}
              commentsToShow={4}
              post={post}
              actionable
              onPostChange={handlePostChange}
            ></PostCard>
          </div>
        ))}

      <Fab
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        color="primary"
        aria-label="edit"
        onClick={handleClickOpen}
      >
        <EditIcon />
      </Fab>
      <ThemedDialog open={open} onClose={handleClose}>
        <DialogTitle>New Post</DialogTitle>
        <DialogContent>
          <EditPostForm onCancel={handleClose} onSubmit={handleFormSubmit} />
        </DialogContent>
      </ThemedDialog>
      {/* <AlertNotification type="success" text="text" /> */}
    </div>
  );
};

const ThemedDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    background: theme.palette.background.paper,
    filter: "drop-shadow(0 0 0.5rem #444444)",
  },
  "& .MuiDialogActions-root": {
    padding: "16px",
  },
}));

export default withSnackbar(Posts);
