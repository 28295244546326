import { useLocation, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import PostCard from "./post-card";
import React from "react";
import { createBrowserHistory } from "history";

const Post = () => {
  const [post, setPost] = useState([]);
  const { id } = useParams();
  const location = useLocation();

  const postData = location.state?.post;
  const history = createBrowserHistory();
  history.replace("", null);

  const handlePostChange = () => {
    getPost();
  };

  const getPost = async () => {
    const resp = await fetch(
      "https://backend.mihirmanek.workers.dev/posts/" + id
    );
    const postsResp = await resp.json();
    setPost(postsResp);
  };

  useEffect(() => {
    if (postData) {
      setPost(postData);
    } else {
      getPost();
    }
  }, []);

  return <PostCard onPostChange={handlePostChange} post={post} />;
};

export default Post;
