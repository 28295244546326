import { Box, Button, TextField } from "@mui/material";
import React from "react";

const EditPostForm = ({ onSubmit, onCancel }) => {
  const [formData, setFormData] = React.useState({ title: "", content: "" });
  const handleTitleChange = (event) => {
    updateFormData({ ...formData, title: event.target.value });
  };
  const handleContentChange = (event) => {
    updateFormData({ ...formData, content: event.target.value });
  };

  const updateFormData = (formData) => {
    setFormData({ ...formData });
  };

  const handleSubmit = () => {
    if (formData.title && formData.content) {
      onSubmit({ ...formData });
    }
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { margin: "8px 0" },
        "& .form-actions": { display: "flex", justifyContent: "flex-end" },
        "& .form-actions .MuiButton-root": { margin: "16px 0px 16px 10px" },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        label="Title"
        required
        fullWidth
        value={formData.title}
        onChange={handleTitleChange}
      />
      <TextField
        label="Content"
        required
        fullWidth
        multiline
        rows={4}
        maxRows={6}
        value={formData.content}
        onChange={handleContentChange}
      />
      <div className="form-actions">
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          Post
        </Button>
      </div>
    </Box>
  );
};
export default EditPostForm;
