import { Outlet } from "react-router-dom";
import "./App.css";
import { useMemo } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import { SnackbarProvider } from "notistack";
import { Stack, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  // const prefersDarkMode = false;
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
          tonalOffset: 0,
          contrastThreshold: 10,
          warning: {
            main: "#ffffff",
          },
          success: {
            main: "#00ff00",
          },
          error: {
            main: "#ff0000",
            dark: "#d32f2f",
          },
        },
      }),
    [prefersDarkMode]
  );

  const username = localStorage.getItem("username");
  return (
    <SnackbarProvider
      hideIconVariant
      maxSnack={3}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="xl">
          <Box sx={{ my: 4 }}>
            <div>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                sx={{
                  borderBottom: "solid 1px",
                  paddingBottom: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <Typography variant="h4" component="div">
                  Just Another Social Network
                </Typography>
                <Stack direction="row" spacing={1}>
                  <AccountCircleIcon />
                  <div>{username}</div>
                </Stack>
              </Stack>
              <div>
                <Outlet />
              </div>
            </div>
          </Box>
        </Container>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
