import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import ModeCommentOutlinedIcon from "@mui/icons-material/ModeCommentOutlined";
import SendIcon from "@mui/icons-material/Send";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import React from "react";
import { Link } from "react-router-dom";
import { values } from "lodash";

const PostCard = ({ onPostChange, ...props }) => {
  const {
    post,
    actionable,
    commentsToShow = post.comments?.length || 0,
  } = props;
  const username = localStorage.getItem("username");

  const [comment, setComment] = React.useState({ username, content: "" });
  const [showCommentInput, setShowCommentInput] = React.useState(false);

  const handleLike = () => {
    const likePost = async (post) => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username: username }),
      };
      const resp = await fetch(
        "https://backend.mihirmanek.workers.dev/posts/" + post.id + "/likes",
        requestOptions
      );
      onPostChange();
    };
    likePost(post);
  };

  const handleComment = () => {
    setShowCommentInput(!showCommentInput);
  };

  const handleSubmitComment = () => {
    const commentOnPost = async (post) => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(comment),
      };
      const resp = await fetch(
        "https://backend.mihirmanek.workers.dev/posts/" + post.id + "/comments",
        requestOptions
      );
      onPostChange();
      setShowCommentInput(false);
      setComment({ ...comment, content: "" });
    };
    commentOnPost(post);
  };

  const handleCommentChange = (event) => {
    setComment({ ...comment, content: event.target.value });
  };

  return (
    <div>
      {post ? (
        <Card sx={{ marginBottom: "10px" }} variant="outlined">
          {actionable ? (
            <ActionableContent post={post} />
          ) : (
            <Content post={post} />
          )}
          <Divider />
          <CardActions>
            <IconButton aria-label="like post" onClick={handleLike}>
              {post.likes?.find((l) => l.username === username) ? (
                <FavoriteIcon sx={{ color: "#cc2222" }} />
              ) : (
                <FavoriteBorderIcon sx={{ color: "#cc2222" }} />
              )}
            </IconButton>
            <Typography variant="body1">
              {post.likes ? post.likes.length : 0}
            </Typography>
            <IconButton aria-label="comment on post" onClick={handleComment}>
              {post.comments?.find((l) => l.username === username) ? (
                <ModeCommentIcon sx={{ color: "#62a1ff" }} />
              ) : (
                <ModeCommentOutlinedIcon sx={{ color: "#62a1ff" }} />
              )}
            </IconButton>
            <Typography variant="body1">
              {post.comments ? post.comments.length : 0}
            </Typography>
          </CardActions>

          {showCommentInput ? (
            <React.Fragment>
              <Divider />
              <CardContent>
                <FormControl sx={{ width: "100%" }} variant="outlined">
                  <OutlinedInput
                    onChange={handleCommentChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={handleSubmitComment}>
                          <SendIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    inputProps={{
                      "aria-label": "comment",
                    }}
                    placeholder="Your comment"
                  />
                </FormControl>
              </CardContent>
            </React.Fragment>
          ) : (
            ""
          )}
          {post.comments
            ?.sort(
              (a, b) =>
                new Date(b.timestamp).getTime() -
                new Date(a.timestamp).getTime()
            )
            .slice(0, commentsToShow)
            .map((comment) => (
              <React.Fragment key={comment.id}>
                <Divider />
                <CardContent>
                  <Stack
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="flex-end"
                    direction="row"
                  >
                    <div>
                      <Typography variant="button">
                        {comment.username} :
                      </Typography>
                      <Typography variant="body1">{comment.content}</Typography>
                    </div>
                    <Typography variant="caption">
                      {comment.timestamp
                        ? new Date(comment.timestamp).toLocaleString()
                        : ""}
                    </Typography>
                  </Stack>
                </CardContent>
              </React.Fragment>
            ))}
        </Card>
      ) : (
        <div>No post data</div>
      )}
    </div>
  );
};
const Content = ({ post }) => {
  return (
    <CardContent>
      <Typography variant="h4" gutterBottom>
        {post.title}
      </Typography>
      <Typography variant="body2">{post.content}</Typography>
    </CardContent>
  );
};

const ActionableContent = ({ post }) => {
  return (
    <CardActionArea component={Link} to={`/posts/${post.id}`} state={{ post }}>
      <Content post={post} />
    </CardActionArea>
  );
};

export default PostCard;
